import React, { useEffect, useRef } from 'react';
import Layout from '../components/layout';
import WebinarRegistrationPopup from '../components/Popup/webinar-registration-popup';
import SEO from "../components/seo";
import IPProtectionImg from '../images/aws-openai-soc.jpg';
import '../styles/webinar-page.css';

const WebinarPatentPowerPlay = () => {
    const seo = {
        metaDesc: 'Patent Power Play: Turn Your Product Features into Business Assets - Inventiv.org'
    }
    const WEBINAR_PATENT_POWER_PLAY = "https://webinarkit.com/webinar/registration/oneclick/67a1b517357858ffc388f6d3?date=jit_15";
    const childRef = useRef();
    const openPopup = () => {
        //
        childRef.current?.open(WEBINAR_PATENT_POWER_PLAY);
    }
    //
    useEffect(() => {
        document?.body.classList.add('no-home');
        //
    }, []);

    return (
        <Layout activeMenu={'webinar'}>
            <SEO title="Webinar Patent Power Play: Turn Your Product Features into Business Assets - Inventiv.org" canonical='/webinar-ai-meets-ip-welcome-to-the-future-of-patent-drafting' seo={seo} />

            <div class="sub-banner" style={{ height: 'auto', backgroundColor: '#0C6873' }}>
                <div class="container py-5">
                    <div class="text-center">
                        <h1 className='text-white'> AI PATENT DRAFTING IN ACTION </h1>
                        <h6 className='text-white'>Presented by Bao Tran, Founder of PowerPatent</h6>
                    </div>
                </div>
            </div>

            {/* <!-- Rsvp start --> */}
            <div class="rsvp content-area-2" id="rsvp">
                <div class="container px-5">
                    <div className='px-5'>
                        <div class="row">
                            <div class="col-12 wow fadeInUp delay-04s">
                                <div class="row">
                                    <h1 className="text-center bold">Patent Power Play: Turn Your Product Features into Business Assets</h1>
                                    <h5 className='text-center'>Protecting your unique product innovations through patents allows you to transform them into valuable intellectual property, creating opportunities to drive revenue, deter competitors, and solidify your market position for long-term success.</h5>
                                </div>
                            </div>
                        </div>
                        <div className='my-5'>
                            <div className='row'>
                                <div class=" text-center col-md-12 wow fadeInUp delay-04s">
                                    <div className='circular_image'>
                                        <img style={{ display: 'block', objectFit: 'cover' }} src='https://webinarkit.com/img/landingdefault_sm.jpg' alt='An AI-Powered Patent Drafting Solution'></img>
                                    </div>
                                    <h5 className='bold mt-2'>Instant watch session available. Join now!</h5>
                                </div>

                            </div>
                            <div className='row justify-content-center'>
                                <div className='col-4'>
                                    <a href="#" onClick={e => { e.preventDefault(); openPopup() }} class="btn btn-default my-3" style={{ width: '100%' }}>REGISTER NOW</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container px-5'>
                <div class="d-flex justify-content-center">
                    <img src={IPProtectionImg} alt='Our lawyers have worked on IP protection for' />
                </div>
            </div>
            <WebinarRegistrationPopup ref={childRef}></WebinarRegistrationPopup>
            {/* <!-- Rsvp end --> */}
        </Layout>
    )
};

export default WebinarPatentPowerPlay;